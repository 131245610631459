<template>


	<el-container>
		<el-aside class="aside">
			<el-tabs tab-position="left" style="height: 100%;" @tab-click="tabClick">
				<el-tab-pane v-for="(item,index) in tab" :key="index" :label="item.label"></el-tab-pane>
			</el-tabs>
		</el-aside>
		<el-main class="main">
			<router-view></router-view>
		</el-main>

	</el-container>
</template>
<script>
	export default {
		data() {
			return {
				active: '',
				tab: [{
					label: '拼团',
					url: '/marketingCenter/group'
				}]
			};
		},
		mounted() {},
		methods: {
			tabClick(val) {
				this.$router.push(this.tab[val.index].url)
			}
		},
	};
</script>

<style scoped lang="scss">
	.main{
		margin-left: 123px;
	}
	.aside {
		overflow-x: hidden;
		height: 100%;
		position: fixed;
		z-index: 99;
		width: 123px !important;

		.el-tabs--left >>> .el-tabs__header.is-left {
			width: 123px;
		}

	}
</style>
